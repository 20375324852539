import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  userChefList,
  updateStatus,
  onErrorStopLoad,
} from "../../redux/slices/web";
import ReactPaginate from "react-paginate";
import CustomModal from "../Modal/CustomModal";
import TableModal from "../Modal/TableModal";
import * as images from "../../utilities/images";
import ReactFlagsSelect from "react-flags-select";
import { countryList } from "../../config/countryList";
import DriverDocuments from "../Modal/DriverDocuments";

const UserChefListData = (data) => {
  document.title = "Users List";
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [key, setKey] = useState(Math.random());
  const [chefInfo, setChefInfo] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [active, setActive] = useState("");
  const [verified, setVerified] = useState("");
  const [role, setRole] = useState(state ? state : "");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [searchCountry, setSearchCountry] = useState("");
  const [driverDocInfo, setDriverDocInfo] = useState({});
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  // getting all chef and user accounts
  useEffect(() => {
    getAllAccountsDetails();
  }, [active, role, verified, search, searchCountry]);

  // getting all account information
  const getAllAccountsDetails = (page = currentPage) => {
    let params = {
      role: role,
      status: active,
      documentVerified: verified,
      limit: 10,
      page: page,
      search: search,
      searchCountry: searchCountry,
    };

    dispatch(
      userChefList({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setIsLoading(false);
            setUsersData(res?.data?.data?.data);
            setPageCount(res.data.data.total_pages);
          }
        },
      })
    );
  };

  // update status
  const updateUserChefStatus = (id, status) => {
    let params = {
      id: id,
      status: status,
    };
    dispatch(
      updateStatus({
        ...params,
        cb(res) {
          if (res.status === 200) {
            getAllAccountsDetails();
          }
        },
      })
    );
  };

  // stop loader on page reload
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
    getAllAccountsDetails(selected + 1);
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //  open modal
  const handleOpneModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
    setChefInfo(data);
    setDriverDocInfo(data);
  };

  // select country
  const handleSelectCountry = (selectedCountry) => {
    setCountry(selectedCountry);
    const countryName = countryList?.find(
      (country) => country?.code === selectedCountry
    );
    setSearchCountry(countryName?.name);
  };

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Users List</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}

      <section className="content commonBox_  userlistbox">
        <div className="container-fluid">
          <div className="mt-4 userFilter">
            <div className="commonFilterHeader country-dropdown-select">
              <ReactFlagsSelect
                className="select-country"
                selected={country}
                onSelect={handleSelectCountry}
                placeholder="Filter By Country"
                searchPlaceholder="Search..."
                searchable={true}
              />
            </div>
            <div className="d-flex commonFilterHeader">
              <p className="commonBox_Heading m-0 text-capitalize">
                {active || "Filter By Status"}
              </p>
              <div className="dropdown filterDropdown userListFilter">
                <button
                  className="filterBtn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-filter"></i>
                </button>
                <ul className="dropdown-menu dropdown-menu-end dropboxmenu">
                  <li onClick={(e) => setActive("active")}>
                    <Link className="dropdown-item font-14 subtext" href="#">
                      active
                    </Link>
                  </li>
                  <li onClick={(e) => setActive("inactive")}>
                    <Link className="dropdown-item font-14 subtext" href="#">
                      InActive
                    </Link>
                  </li>
                  {active && (
                    <li onClick={(e) => setActive("")}>
                      <Link className="dropdown-item font-14 subtext" href="#">
                        None of these
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="d-flex commonFilterHeader  ">
              <p className="commonBox_Heading m-0 text-capitalize">
                {role || "Filter By Role"}
              </p>
              <div className="dropdown filterDropdown userListFilter ">
                <button
                  className="filterBtn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-filter"></i>
                </button>
                <ul className="dropdown-menu dropdown-menu-end dropboxmenu">
                  <li onClick={(e) => setRole("user")}>
                    <Link className="dropdown-item font-14 subtext" href="#">
                      user
                    </Link>
                  </li>
                  <li onClick={(e) => setRole("chef")}>
                    <Link className="dropdown-item font-14 subtext" href="#">
                      chef
                    </Link>
                  </li>
                  <li onClick={(e) => setRole("driver")}>
                    <Link className="dropdown-item font-14 subtext" href="#">
                      Driver
                    </Link>
                  </li>
                  {role && (
                    <li onClick={(e) => setRole("")}>
                      <Link className="dropdown-item font-14 subtext" href="#">
                        None of these
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="d-flex commonFilterHeader ">
              <p className="commonBox_Heading m-0 text-capitalize">
                {verified || "Filter By Verification"}
              </p>
              <div className="dropdown filterDropdown userListFilter ">
                <button
                  className="filterBtn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-filter"></i>
                </button>
                <ul className="dropdown-menu dropdown-menu-end dropboxmenu">
                  <li onClick={() => setVerified("pending")}>
                    <Link className="dropdown-item font-14 subtext" href="#">
                      Pending
                    </Link>
                  </li>
                  <li onClick={() => setVerified("rejected")}>
                    <Link className="dropdown-item font-14 subtext" href="#">
                      Rejected
                    </Link>
                  </li>
                  <li onClick={() => setVerified("verified")}>
                    <Link className="dropdown-item font-14 subtext" href="#">
                      Verified
                    </Link>
                  </li>
                  {verified ? (
                    <li onClick={() => setVerified("")}>
                      <Link className="dropdown-item font-14 subtext" href="#">
                        None of these
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="d-flex commonFilterHeader">
              <input
                type="search"
                className="filterSearch"
                placeholder="search..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <table className="usertable">
            <tr className="tableborder">
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>Country</th>
              <th>Verification Status</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>
            </tr>

            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center ">
                      <div className="spinner-grow " role="status"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {usersData && usersData.length > 0 ? (
                  <>
                    {usersData?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item?.userInfo?.firstName +
                            " " +
                            item?.userInfo?.lastName}
                        </td>
                        <td className="text-lowercase">{item?.email}</td>
                        <td className="text-lowercase">
                          {item?.dialCode && item?.phoneNo
                            ? `${item?.dialCode} ${item?.phoneNo}`
                            : "---"}
                        </td>
                        <>
                          <td className="text-capitalize country-short">
                            {item?.chefInfo?.country?.length > 14
                              ? `${item?.chefInfo?.country?.slice(0, 14)}...`
                              : `${item?.chefInfo?.country?.slice(0, 14)}` ||
                                "---"}
                          </td>
                          <td
                            className={
                              item?.role === "chef"
                                ? item?.chefInfo?.documentVerified === "pending"
                                  ? "text-capitalize verification-status-pending"
                                  : item?.chefInfo?.documentVerified ===
                                    "rejected"
                                  ? "text-capitalize text-danger"
                                  : item?.chefInfo?.documentVerified ===
                                    "verified"
                                  ? "text-capitalize verified-status"
                                  : ""
                                : item?.driverInfo?.documentVerified ===
                                  "pending"
                                ? "text-capitalize verification-status-pending"
                                : item?.driverInfo?.documentVerified ===
                                  "rejected"
                                ? "text-capitalize text-danger"
                                : item?.driverInfo?.documentVerified ===
                                  "verified"
                                ? "text-capitalize verified-status"
                                : ""
                            }
                          >
                            <>
                              {item?.role === "user" ? (
                                <p className="status-slash">---</p>
                              ) : item?.role === "chef" ? (
                                item?.chefInfo?.documentVerified
                              ) : (
                                item?.driverInfo?.documentVerified
                              )}
                            </>
                          </td>
                        </>
                        <td>{item?.role}</td>
                        <td
                          className={
                            item?.status === "Active"
                              ? "activeBtn"
                              : "inactivebtn"
                          }
                        >
                          {item?.status}
                        </td>
                        <td>
                          <div className="dropdown filterDropdown">
                            <button
                              className="barbtn"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa fa-ellipsis-v"></i>
                            </button>
                            {(item.role === "chef" ||
                              item.role === "driver") && (
                              <button
                                title="view document"
                                className="view-document-btn"
                                onClick={() =>
                                  handleOpneModal(
                                    item.role === "chef"
                                      ? "tablebtn"
                                      : "driverInfo",
                                    item
                                  )
                                }
                              >
                                {`${
                                  item?.chefInfo?.documentVerified ===
                                  "verified"
                                    ? "view document"
                                    : "verify document"
                                }`}
                              </button>
                            )}
                            <ul className="dropdown-menu">
                              {item?.status === "Inactive" ? (
                                <li>
                                  <Link
                                    className="dropdown-item font-14 userText"
                                    href="#"
                                    onClick={() => {
                                      updateUserChefStatus(item?._id, "Active");
                                    }}
                                  >
                                    Active
                                  </Link>
                                </li>
                              ) : (
                                <li>
                                  <Link
                                    className="dropdown-item font-14 userText"
                                    href="#"
                                    onClick={() => {
                                      updateUserChefStatus(
                                        item?._id,
                                        "Inactive"
                                      );
                                    }}
                                  >
                                    InActive
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center ">
                        <p className="p-2">No data found</p>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </table>
          <div className="paginationMain_ mt-4">
            {usersData && usersData.length > 0 && (
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                pageCount={pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "tablebtn" || modalDetail.flag === "driverInfo"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "tablebtn" || modalDetail.flag === "driverInfo"
            ? "tablemodalbtn"
            : ""
        }
        child={
          modalDetail.flag === "tablebtn" ? (
            <TableModal
              close={() => handleOnCloseModal()}
              chefInfomation={chefInfo}
              getAllAccountsDetails={getAllAccountsDetails}
            />
          ) : modalDetail.flag === "driverInfo" ? (
            <DriverDocuments
              close={() => handleOnCloseModal()}
              driverDocInfo={driverDocInfo}
              getAllAccountsDetails={getAllAccountsDetails}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "tablebtn" ? (
            <>
              <div className="verified-main">
                <h2 className="modal_Heading">Chef Document</h2>
              </div>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancel"
                />
              </p>
            </>
          ) : modalDetail.flag === "driverInfo" ? (
            <>
              <div className="verified-main">
                <h2 className="modal_Heading">Driver Document</h2>
              </div>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancel"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default UserChefListData;
